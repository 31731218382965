/***
* NOTE: This file is ignored from git tracking. In a CI environment it is
* generated by firebase-ci based on config in .firebaserc (see <% if(ciProvider === 'travis') { %>.travis.yml<% } else { %>.gitlab-ci.yaml<% } %>).
* This is done so that environment specific settings can be applied.
*/

export const env = 'dev'

// Config for firebase
// development
// export const firebase = {
// apiKey: 'AIzaSyDGTvpAcr4eyuKVMDYDh-VOZmZwqkMwe34',
// authDomain: 'fir-test-e4371.firebaseapp.com',
// databaseURL: 'https://fir-test-e4371.firebaseio.com',
// projectId: 'fir-test-e4371',
// storageBucket: 'fir-test-e4371.appspot.com',
// messagingSenderId: '366818957783',
// measurementId: 'G-R2M6DYCL0T',
// appId: '1:366818957783:web:bcd78581f86ca095d45392'
// }

// production
export const firebase = {
apiKey: 'AIzaSyDwOKbBsKywB-IukbYE1atG8cGiKAvpXGM',
authDomain: 'azm-sales-inspector-baf39.firebaseapp.com',
databaseURL: 'https://azm-sales-inspector-baf39.firebaseio.com',
projectId: 'azm-sales-inspector-baf39',
storageBucket: 'azm-sales-inspector-baf39.appspot.com',
messagingSenderId: '291512952304',
measurementId: 'G-8DTVD12HQC',
appId: '1:291512952304:web:a3ec5c9629d37578d66935',
region: 'asia-east2'
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
enableLogging: false, // enable/disable Firebase Database Logging
}


export const publicVapidKey = 'BNdDKnYIFtOi_MaeGcUqgDRNRnjiCWQ1lfQFTJ4fsKLM0rAPIa1lMjMdUUx4MG8nMPw-SVIbEb5hVBWC5r0zsso'

export const initialMapCenter = {
lat: 29.2905331,
lng: 47.9232407
}

// export const initialMapCenter = {
// lat: 25.451831526,
// lng: 81.818996724
// }

export const desiredAccuracy = 20.0;

export const mapApiKey = 'AIzaSyA31-_trvD0iZuDwHOISV4K2esFkx_fwq4'

export default {
env,
firebase,
reduxFirebase,
publicVapidKey,
initialMapCenter,
desiredAccuracy,
mapApiKey
}

